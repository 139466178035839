<template>
  <select-employees
    :value="selectEmployees"
    :width="'80vw'"
    :height="'80vh'"
    :selectType="type"
    :dialogTitle="dialogTitle"
    @input="input"
  >
    <template #activator="scope">
      <v-menu offset-y v-model="showMenu">
        <template v-slot:activator>
          <m-combobox
            @menuActive="handlerMenuActive($event)"
            :value="items"
            @input="input2"
            :label="label"
            :item-text="displayItem"
            :chipColor="chipColor"
            multiple
          >
            <template #selection="{ item, parent }">
              <v-menu offset-y min-width="30rem">
                <template v-slot:activator="{ attrs, on }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="primary--text m__combobox__chip"
                    @click:close="parent.selectItem(item)"
                    color="#E8ECFF"
                    @click.stop
                  >
                    <span class="m__combobox__chip__text">
                      {{ titles[item.type] }} ({{ item.items.length }})
                    </span>
                    <m-icon icon="mdi-dots-horizontal" right></m-icon>
                  </v-chip>
                </template>

                <v-list>
                  <v-list-item
                    v-for="item2 in item.items"
                    :key="item2.type"
                    link
                  >
                    <v-list-item-title
                      :style="
                        'color: ' +
                        (typeof chipColor === 'function'
                          ? chipColor(item2)
                          : chipColor) +
                        ';'
                      "
                      v-text="item2.displayName"
                    ></v-list-item-title>
                    <v-list-item-action>
                      <m-icon
                        icon="mdi-close"
                        :disabled="item2.Date"
                        @click.stop="
                          $emit(
                            'input',
                            selectEmployees.filter((e) => e.Id !== item2.Id)
                          )
                        "
                      ></m-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </m-combobox>
        </template>
        <v-list>
          <v-list-item
            v-on="scope.on"
            @click="type = 'employee'"
            v-if="showItem('employee')"
          >
            <v-list-item-title> Ознакомить сотрудников </v-list-item-title>
          </v-list-item>
          <v-list-item v-on="scope.on" @click="type = 'partition'">
            <v-list-item-title> Ознакомить подразделения </v-list-item-title>
          </v-list-item>
          <v-list-item v-on="scope.on" @click="type = 'position'">
            <v-list-item-title> Ознакомить по должностям </v-list-item-title>
          </v-list-item>

          <v-list-item v-on="scope.on" @click="type = 'positionPartition'">
            <v-list-item-title>
              Ознакомить по должностям в подразделениях
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </select-employees>
</template>

<script>
import MCombobox from "../../../components/base/inputs/mCombobox.vue";
import SelectEmployees from "../../../components/organization/employees/SelectEmployees.vue";
export default {
  components: { SelectEmployees, MCombobox },
  model: {
    prop: "selectEmployees",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    selectEmployees: {
      type: Array,
      default: () => [],
    },
    onlyEmployee: {
      type: Boolean,
      default: true,
    },
    chipColor: [Function, String],
    hideItem: [String, Array],
  },
  data() {
    return {
      showMenu: false,
      type: "employee",
      titles: [
        "Сотрудники",
        "Должности",
        "Подразделения",
        "Должности по подразделениям",
      ],
    };
  },
  computed: {
    items() {
      if (!this.selectEmployees?.length) return [];
      const items = [
        { items: [] },
        { items: [] },
        { items: [] },
        { items: [] },
      ];
      for (let i = 0; i < this.selectEmployees.length; i++) {
        const type = this.getType(this.selectEmployees[i]);
        const itemTmp = {
          ...this.selectEmployees[i],
          displayName: this.displayItem(this.selectEmployees[i]),
        };

        switch (type) {
          case "employee":
            items[0].items.push(itemTmp);
            items[0].type = 0;
            break;
          case "position":
            items[1].items.push(itemTmp);
            items[1].type = 1;
            break;
          case "partition":
            items[2].items.push(itemTmp);
            items[2].type = 2;
            break;
          case "positionPartition":
            items[3].items.push(itemTmp);
            items[3].type = 3;
            break;
        }
      }

      for (let i = 0; i < items.length; i++) {
        items[i].items = items[i].items.sort(function (a, b) {
          return a?.displayName.localeCompare(b.displayName);
        });
      }

      return items.filter((e) => e.items.length);
    },
    dialogTitle() {
      switch (this.type) {
        case "employee":
          return "Выбор сотрудников";
        case "position":
          return "Выбор должностей";
        case "partition":
          return "Выбор подразделений";
        case "positionPartition":
          return "Выбор должностей в подразделениях";
      }
      return "";
    },
  },
  methods: {
    showItem(name) {
      if (name === this.hideItem) return false;
      return true;
    },
    handlerMenuActive(e) {
      setTimeout(() => {
        this.showMenu = e.value;
      }, 1);
    },
    getType(item) {
      if (item.Employee) {
        return "employee";
      } else if (item.Position && !item.Partition) {
        return "position";
      } else if (item.Partition && !item.Position) {
        return "partition";
      } else if (item.Partition && item.Position) {
        return "positionPartition";
      }
    },
    displayItem(item) {
      if (item.Employee) {
        return (
          this.$options.filters.PersonShortName(item.Employee) +
          (item.Position ? ", " + item.Position.Name : "")
        );
      } else if (item.Position && !item.Partition) {
        return item.Position.Name;
      } else if (item.Partition && !item.Position) {
        return item.Partition.Name;
      } else if (item.Partition && item.Position) {
        return item.Position.Name + ", " + item.Partition.Name;
      }
    },
    input2(arr) {
      this.$emit("input", arr);
    },
    input(arr) {
      let newArr = this.selectEmployees;

      if (this.type === "employee") {
        newArr = newArr.filter((e) => !e.Employee);
        newArr = [
          ...newArr,
          ...arr.map((e) => {
            return {
              Employee: { ...e },
              Partition: null,
              Position: null,
              Id: -Math.round(Math.random() * 100000),
            };
          }),
        ];
      } else if (this.type === "position") {
        newArr = newArr.filter((e) => !(!e.Partition && e.Position));
        newArr = [
          ...newArr,
          ...arr.map((e) => {
            return {
              Employee: null,
              Partition: null,
              Position: { ...e },
              Id: -Math.round(Math.random() * 100000),
            };
          }),
        ];
      } else if (this.type === "partition") {
        newArr = newArr.filter((e) => !(e.Partition && !e.Position));
        newArr = [
          ...newArr,
          ...arr.map((e) => {
            return {
              Employee: null,
              Partition: { ...e },
              Position: null,
              Id: -Math.round(Math.random() * 100000),
            };
          }),
        ];
      } else if (this.type === "positionPartition") {
        newArr = newArr.filter((e) => !(e.Partition && e.Position));
        const tmpArr = [];
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr[i].selectPositions.length; j++) {
            tmpArr.push({
              Employee: null,
              Partition: { ...arr[i] },
              Position: { ...arr[i].selectPositions[j] },
              Id: -Math.round(Math.random() * 100000),
            });
          }
        }
        newArr = [...newArr, ...tmpArr];
      }
      this.$emit("input", newArr);
    },
  },
};
</script>
