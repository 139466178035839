var render = function render(){var _vm=this,_c=_vm._self._c;return _c('select-employees',{attrs:{"value":_vm.selectEmployees,"width":'80vw',"height":'80vh',"selectType":_vm.type,"dialogTitle":_vm.dialogTitle},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('m-combobox',{attrs:{"value":_vm.items,"label":_vm.label,"item-text":_vm.displayItem,"chipColor":_vm.chipColor,"multiple":""},on:{"menuActive":function($event){return _vm.handlerMenuActive($event)},"input":_vm.input2},scopedSlots:_vm._u([{key:"selection",fn:function({ item, parent }){return [_c('v-menu',{attrs:{"offset-y":"","min-width":"30rem"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"primary--text m__combobox__chip",attrs:{"color":"#E8ECFF"},on:{"click:close":function($event){return parent.selectItem(item)},"click":function($event){$event.stopPropagation();}}},'v-chip',attrs,false),on),[_c('span',{staticClass:"m__combobox__chip__text"},[_vm._v(" "+_vm._s(_vm.titles[item.type])+" ("+_vm._s(item.items.length)+") ")]),_c('m-icon',{attrs:{"icon":"mdi-dots-horizontal","right":""}})],1)]}}],null,true)},[_c('v-list',_vm._l((item.items),function(item2){return _c('v-list-item',{key:item2.type,attrs:{"link":""}},[_c('v-list-item-title',{style:('color: ' +
                      (typeof _vm.chipColor === 'function'
                        ? _vm.chipColor(item2)
                        : _vm.chipColor) +
                      ';'),domProps:{"textContent":_vm._s(item2.displayName)}}),_c('v-list-item-action',[_c('m-icon',{attrs:{"icon":"mdi-close","disabled":item2.Date},on:{"click":function($event){$event.stopPropagation();_vm.$emit(
                          'input',
                          _vm.selectEmployees.filter((e) => e.Id !== item2.Id)
                        )}}})],1)],1)}),1)],1)]}}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[(_vm.showItem('employee'))?_c('v-list-item',_vm._g({on:{"click":function($event){_vm.type = 'employee'}}},scope.on),[_c('v-list-item-title',[_vm._v(" Ознакомить сотрудников ")])],1):_vm._e(),_c('v-list-item',_vm._g({on:{"click":function($event){_vm.type = 'partition'}}},scope.on),[_c('v-list-item-title',[_vm._v(" Ознакомить подразделения ")])],1),_c('v-list-item',_vm._g({on:{"click":function($event){_vm.type = 'position'}}},scope.on),[_c('v-list-item-title',[_vm._v(" Ознакомить по должностям ")])],1),_c('v-list-item',_vm._g({on:{"click":function($event){_vm.type = 'positionPartition'}}},scope.on),[_c('v-list-item-title',[_vm._v(" Ознакомить по должностям в подразделениях ")])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }